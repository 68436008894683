var myStyle = [{
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{"color": "#444444"}]
    }, {
        "featureType": "administrative.country",
        "elementType": "geometry.fill",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.country",
        "elementType": "labels.text",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.country",
        "elementType": "labels.text.stroke",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.country",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.province",
        "elementType": "labels.text",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "administrative.locality",
        "elementType": "labels.text",
        "stylers": [{"visibility": "off"}]
    }, {"featureType": "landscape", "elementType": "all", "stylers": [{"color": "#f2f2f2"}]}, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "poi.business",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{"saturation": -100}, {"lightness": 45}]
    }, {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{"visibility": "simplified"}]
    }, {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{"visibility": "off"}]
    }, {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}]}, {
        "featureType": "transit",
        "elementType": "labels.text",
        "stylers": [{"visibility": "off"}]
    }, {
        "featureType": "transit.station",
        "elementType": "labels.text",
        "stylers": [{"visibility": "off"}]
    }, {"featureType": "water", "elementType": "all", "stylers": [{"color": "#485b77"}, {"visibility": "on"}]
    }]

    function initialize() {
        var mapOptions = {
            center: new google.maps.LatLng(41.243594, -75.864950),
            zoom: 16,
            scrollwheel: false,
            styles: myStyle
        };
        var map = new google.maps.Map(document.getElementById("map-canvas"),
            mapOptions);
        var myLatLng = new google.maps.LatLng(41.243594, -75.864950);
        var contentString =
            '<div class="row">' +
            '<div class="small-12 columns">' +
            '<h6>United One Resources &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>' +
            '<p>270 North Sherman Street<br />' +
            'Wilkes-Barre, PA 18702</p>' +
            '<a href="https://www.google.com/maps/dir//United+One+Resources/@41.2436555,-75.8999709,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x0:0xbdfaf27198d94adb!2m2!1d-75.8649515!2d41.2435963" target="_blank">Directions</a>' +
            '<br><br>' +
            '</div>' +
            '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });
        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon: 'https://s3.amazonaws.com/uploads.hipchat.com/59732/676053/lB9dF2kw6jHi6lM/map-icon.png'
        });
        marker.setMap(map);
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
        });
    }
    google.maps.event.addDomListener(window, 'load', initialize);